import {useRef, useEffect, useMemo} from 'react'

function useCallbackRef(/** @type any **/ cb) {
    const cbRef = useRef(cb)

    useEffect(() => {
        cbRef.current = cb
    })

    return useMemo(
        () =>
            (/** @type any[] **/ ...args) =>
                cbRef.current?.(...args),
        []
    )
}

export default useCallbackRef
