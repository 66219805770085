import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import {THEME_ENUM} from 'constants/theme.constant'

const {MODE_DARK} = THEME_ENUM

const DoubleSidedImage = ({src, darkModeSrc, alt, ...rest}) => {
    // @ts-ignore
    const mode = useSelector((state) => state.theme.mode)

    return (
        <img src={mode === MODE_DARK ? darkModeSrc : src} alt={alt} {...rest} />
    )
}

DoubleSidedImage.propTypes = {
    darkModeSrc: PropTypes.string,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
}

export default DoubleSidedImage
