import React from 'react'
import classNames from 'classnames'
import {useTabs} from './context'
import PropTypes from 'prop-types'

const TabContent = React.forwardRef((/** @type any **/ props, /** @type any **/ ref) => {
    const {value, children, className, ...rest} = props

    const context = useTabs()
    const isSelected = value === context.value

    const tabContentClass = classNames(
        'tab-content',
        isSelected && 'tab-content-active',
        className
    )

    return (
        <div
            role="tabpanel"
            tabIndex={0}
            className={tabContentClass}
            ref={ref}
            {...rest}
        >
            {isSelected && children}
        </div>
    )
})
TabContent.displayName = 'TabContent'

TabContent.propTypes = {
    value: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
}

export default TabContent
