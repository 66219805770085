import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const TimeLineItem = React.forwardRef((/** @type any **/ props, /** @type any **/ ref) => {
    const {children, className, isLast, media} = props

    return (
        <li
            className={classNames(
                'timeline-item',
                isLast ? 'timeline-item-last' : '',
                className
            )}
            ref={ref}
        >
            <div className="timeline-item-wrapper">
                <div className="timeline-item-media">
                    <div className="timeline-item-media-content">
                        {media || (
                            <div className="timeline-item-media-default"/>
                        )}
                    </div>
                    {!isLast && <div className="timeline-connect"/>}
                </div>
                <div
                    className={classNames(
                        'timeline-item-content',
                        isLast && 'timeline-item-content-last'
                    )}
                >
                    {children}
                </div>
            </div>
        </li>
    )
})
TimeLineItem.displayName = 'TimeLineItem'

TimeLineItem.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    isLast: PropTypes.bool,
    media: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

export default TimeLineItem
