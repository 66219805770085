import Table from './Table'
import THead from './THead'
import TBody from './TBody'
import TFoot from './TFoot'
import Tr from './Tr'
import Th from './Th'
import Td from './Td'
import Sorter from './Sorter'


/**
* @typedef {React.ForwardRefExoticComponent<React.PropsWithChildren<React.ComponentPropsWithoutRef<'table'>>> & {
*   THead: React.ForwardRefExoticComponent<React.PropsWithChildren<React.ComponentPropsWithoutRef<'thead'>>>;
*   TBody: React.ForwardRefExoticComponent<React.PropsWithChildren<React.ComponentPropsWithoutRef<'tbody'>>>;
*   TFoot: React.ForwardRefExoticComponent<React.PropsWithChildren<React.ComponentPropsWithoutRef<'tfoot'>>>;
*   Th: React.ForwardRefExoticComponent<React.PropsWithChildren<React.ComponentPropsWithoutRef<'th'>>>;
*   Tr: React.ForwardRefExoticComponent<React.PropsWithChildren<React.ComponentPropsWithoutRef<'tr'>>>;
*   Td: React.ForwardRefExoticComponent<React.PropsWithChildren<React.ComponentPropsWithoutRef<'td'>>>;
*   Sorter: React.ElementType;
* }} CompoundTable
*/

/** @type {CompoundTable} */
// @ts-ignore
const CompountTable = Table;

CompountTable.THead = THead
CompountTable.TBody = TBody
CompountTable.TFoot = TFoot
CompountTable.Th = Th
CompountTable.Tr = Tr
CompountTable.Td = Td
CompountTable.Sorter = Sorter

export default CompountTable
