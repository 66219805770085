import React from 'react'
import {HiOutlineMenuAlt2, HiOutlineMenu} from 'react-icons/hi'
import PropTypes from 'prop-types'

const NavToggle = ({toggled, className}) => {
    return (
        <div className={className}>
            {toggled ? <HiOutlineMenu/> : <HiOutlineMenuAlt2/>}
        </div>
    )
}
NavToggle.propTypes = {
    toggled: PropTypes.bool,
    className: PropTypes.string,
}

export default NavToggle
