import {createContext} from 'react'

// @ts-ignore
const MenuContext = createContext()

export const MenuContextProvider = MenuContext.Provider

export const MenuContextConsumer = MenuContext.Consumer

export default MenuContext
