import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Container = React.forwardRef((/** @type any **/ props, /** @type any **/ ref) => {
    const {className, children, asElement: Component, ...rest} = props

    return (
        <Component
            ref={ref}
            className={classNames('container mx-auto', className)}
            {...rest}
        >
            {children}
        </Component>
    )
})
Container.displayName = 'Container'

Container.defaultProps = {
    asElement: 'div',
}

Container.propTypes = {
    asElement: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
}
export default Container
