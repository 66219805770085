import {useRef} from 'react'
import uniqueId from 'lodash/uniqueId'
import createUID from '../utils/createUid'

export default function useUniqueId(/** @type any **/ prefix, /** @type number **/ len) {
    const idRef = useRef()

    if (!idRef.current) {
        // @ts-ignore
        idRef.current = `${uniqueId(prefix)}-${createUID(len)}`
    }

    return idRef.current
}
