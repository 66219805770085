import {createContext} from 'react'

// @ts-ignore
const CollapseContext = createContext()

export const CollapseContextProvider = CollapseContext.Provider

export const CollapseContextConsumer = CollapseContext.Consumer

export default CollapseContext
