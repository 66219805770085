const useMergedRef =
    (/** @type any[] **/ ...refs) =>
        (/** @type any **/ element) =>
            refs.forEach((/** @type any **/ ref) => {
                if (typeof ref === 'function') {
                    ref(element)
                } else if (ref && typeof ref === 'object') {
                    ref.current = element
                }
            })

export default useMergedRef
