import React from 'react'
import classNames from 'classnames'
import Button from '../../Buttons'
import PropTypes from 'prop-types'
import {HiChevronRight, HiChevronLeft} from 'react-icons/hi'

const Header = (/** @type any **/ props) => {
    const {
        hasNext,
        hasPrevious,
        onNext,
        onPrevious,
        onNextLevel,
        className,
        label,
        nextLevelDisabled,
        style,
        nextLabel,
        previousLabel,
        preventLevelFocus = false,
        renderCenter = false,
        preventFocus,
        children,
        ...rest
    } = props

    const headerLabel = (
        <button
            className="picker-header-label"
            disabled={nextLevelDisabled}
            onClick={onNextLevel}
            tabIndex={preventLevelFocus ? -1 : 0}
            onMouseDown={(event) => preventFocus && event.preventDefault()}
            type="button"
        >
            {label}
        </button>
    )

    const renderChildren = children ? children : headerLabel

    return (
        <div
            className="picker-header flex items-center justify-between mb-2"
            {...rest}
        >
            {!renderCenter && renderChildren}
            <div
                className={classNames(
                    renderCenter && 'justify-between w-full',
                    'flex items-center rtl:flex-row-reverse'
                )}
            >
                <Button
                    type="button"
                    variant="plain"
                    className={classNames(
                        !hasPrevious &&
                        renderCenter &&
                        'opacity-0 cursor-default'
                    )}
                    size="sm"
                    icon={<HiChevronLeft/>}
                    disabled={!hasPrevious}
                    onClick={onPrevious}
                    aria-label={previousLabel}
                    onMouseDown={(/** @type any **/ event) =>
                        preventFocus && event.preventDefault()
                    }
                />
                {renderCenter && renderChildren}
                <Button
                    type="button"
                    variant="plain"
                    className={classNames(
                        !hasNext && renderCenter && 'opacity-0 cursor-default'
                    )}
                    size="sm"
                    icon={<HiChevronRight/>}
                    disabled={!hasNext}
                    onClick={onNext}
                    aria-label={nextLabel}
                    onMouseDown={(/** @type any **/ event) =>
                        preventFocus && event.preventDefault()
                    }
                />
            </div>
        </div>
    )
}
Header.propTypes = {
    hasNext: PropTypes.bool,
    hasPrevious: PropTypes.bool,
    onNext: PropTypes.func,
    onPrevious: PropTypes.func,
    onNextLevel: PropTypes.func,
    className: PropTypes.string,
    label: PropTypes.string,
    nextLevelDisabled: PropTypes.bool,
    style: PropTypes.object,
    nextLabel: PropTypes.string,
    previousLabel: PropTypes.string,
    preventLevelFocus: PropTypes.bool,
    renderCenter: PropTypes.bool,
    preventFocus: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
}

export default Header
