import React, {useState} from 'react'
import {Input} from 'components/ui'
import {HiOutlineEyeOff, HiOutlineEye} from 'react-icons/hi'
import PropTypes from 'prop-types'

const PasswordInput = (/** @type any **/ props) => {
    const {onVisibleChange, ...rest} = props

    const [pwInputType, setPwInputType] = useState('password')

    const onPasswordVisibleClick = (/** @type any **/ e) => {
        e.preventDefault()
        const nextValue = pwInputType === 'password' ? 'text' : 'password'
        setPwInputType(nextValue)
        onVisibleChange?.(nextValue === 'text')
    }

    return (
        <Input
            {...rest}
            type={pwInputType}
            suffix={
                <span
                    className="cursor-pointer text-xl"
                    onClick={(e) => onPasswordVisibleClick(e)}
                >
                    {pwInputType === 'password' ? (
                        <HiOutlineEyeOff/>
                    ) : (
                        <HiOutlineEye/>
                    )}
                </span>
            }
        />
    )
}
PasswordInput.propTypes = {
    onVisibleChange: PropTypes.func,
}

export default PasswordInput
