import React from 'react'
import {useSelector} from 'react-redux'
import {ConfigProvider} from 'components/ui'
import useDarkMode from 'utils/hooks/useDarkMode'
import {themeConfig} from 'configs/theme.config'
import PropTypes from 'prop-types'

const Theme = (/** @type any **/ props) => {
    // @ts-ignore
    const theme = useSelector((state) => state.theme)
    // @ts-ignore
    const locale = useSelector((state) => state.locale.currentLang)
    const [isDark] = useDarkMode()

    const currentTheme = {
        mode: isDark ? 'dark' : 'light',
        ...themeConfig,
        ...theme,
        ...{locale},
    }

    return (
        <ConfigProvider value={currentTheme}>{props.children}</ConfigProvider>
    )
}
Theme.propTypes = {
    children: PropTypes.node,
}

export default Theme
