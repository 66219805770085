import React from 'react'
import classNames from 'classnames'
import {HiChevronRight} from 'react-icons/hi'
import PropTypes from 'prop-types'

const Next = (/** @type any **/ props) => {
    const {currentPage, pageCount, pagerClass, onNext} = props

    const disabled = currentPage === pageCount || pageCount === 0

    const onNextClick = (/** @type any **/ e) => {
        e.preventDefault()
        if (disabled) {
            return
        }
        onNext(e)
    }

    const pagerNextClass = classNames(
        pagerClass.default,
        'pagination-pager-next',
        disabled ? pagerClass.disabled : pagerClass.inactive
    )

    return (
        <span className={pagerNextClass} onClick={onNextClick}>
            <HiChevronRight/>
        </span>
    )
}
Next.propTypes = {
    currentPage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    pagerClass: PropTypes.object.isRequired,
    onNext: PropTypes.func.isRequired
}

export default Next
