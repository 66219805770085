import Dropdown from './Dropdown'
import DropdownItem from './DropdownItem'
import DropdownMenu from './DropdownMenu'

// @ts-ignore
Dropdown.Item = DropdownItem
// @ts-ignore
Dropdown.Menu = DropdownMenu

export default Dropdown
