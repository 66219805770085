import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const SvgIcon = React.forwardRef((/** @type any **/ props, /** @type any **/ ref) => {
    const {children, className, ...rest} = props

    return (
        <span
            ref={ref}
            className={classNames('inline-flex', className)}
            {...rest}
        >
            {children}
        </span>
    )
})
SvgIcon.displayName = 'SvgIcon'
SvgIcon.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
}

export default SvgIcon
