import React from 'react'
import classNames from 'classnames'
import {HiChevronLeft} from 'react-icons/hi'
import PropTypes from 'prop-types'

const Prev = (/** @type any **/ props) => {
    const {currentPage, pagerClass, onPrev} = props

    const disabled = currentPage <= 1

    const onPrevClick = (/** @type any **/ e) => {
        if (disabled) {
            return
        }
        onPrev(e)
    }

    const pagerPrevClass = classNames(
        pagerClass.default,
        'pagination-pager-prev',
        disabled ? pagerClass.disabled : pagerClass.inactive
    )

    return (
        <span className={pagerPrevClass} onClick={onPrevClick}>
            <HiChevronLeft/>
        </span>
    )
}
Prev.propTypes = {
    currentPage: PropTypes.number,
    pagerClass: PropTypes.object,
    onPrev: PropTypes.func,
}

export default Prev
