import ToastWrapper, { toastDefaultProps } from "./ToastWrapper";

const defaultWrapperId = "default";
const wrappers = new Map();

/**
 * @param {string} placement
 */
function castPlacment(placement) {
    if (/\top\b/.test(placement)) {
        return "top-full";
    }

    if (/\bottom\b/.test(placement)) {
        return "bottom-full";
    }
}

/**
 * @param {string} wrapperId
 * @param {ToastOptions} props
 */
async function createWrapper(wrapperId, props) {
    // @ts-ignore
    const [wrapper] = await ToastWrapper.getInstance(props);

    wrappers.set(wrapperId || defaultWrapperId, wrapper);

    return wrapper;
}

/**
 * @param {string} wrapperId
 */
function getWrapper(wrapperId) {
    if (wrappers.size === 0) {
        return null;
    }
    return wrappers.get(wrapperId || defaultWrapperId);
}

const toast = (/** @type {any} */ message) => toast.push(message);

/**
 * @typedef {object} ToastOptions
 * @property {string=} placement
 * @property {number=} offsetX
 * @property {number=} offsetY
 * @property {string=} transitionType
 * @property {boolean=} block
 */
toast.push = (/** @type {any} */ message, /** @type {ToastOptions} **/ options = toastDefaultProps) => {
    let id = options.placement;
    if (options.block) {
        id = castPlacment(options.placement);
    }

    let wrapper = getWrapper(id);

    if (wrapper?.current) {
        return wrapper.current.push(message);
    }

    return createWrapper(id ?? "", options).then((ref) => {
        return ref.current?.push(message);
    });
};

toast.remove = (/** @type {any} */ key) => {
    wrappers.forEach((elm) => elm.current.remove(key));
};

toast.removeAll = () => {
    wrappers.forEach((elm) => elm.current.removeAll());
};

export default toast;
