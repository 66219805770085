import React, { useState } from 'react'
import dayjs from 'dayjs'
import {isSameDate} from './utils/isSameDate'
import CalendarBase from './CalendarBase'
import PropTypes from 'prop-types'

const RangeCalendar = React.forwardRef((/** @type any **/ props, /** @type any **/ ref) => {
    const {
        value,
        onChange,
        dayStyle,
        onMouseLeave,
        singleDate,
        dateViewCount,
        paginateBy,
        ...rest
    } = props

    const [hoveredDay, setHoveredDay] = useState(null)
    const [pickedDate, setPickedDate] = useState(null)

    const setRangeDate = (/** @type {Date} */ date) => {
        if (pickedDate instanceof Date) {
            if (isSameDate(date, pickedDate) && !singleDate) {
                setPickedDate(null)
                setHoveredDay(null)
                return null
            }

            const result = [date, pickedDate]
            result.sort((a, b) => a.getTime() - b.getTime())
            onChange(result)
            setPickedDate(null)
            return null
        }

        if (value[0] && isSameDate(date, value[0]) && !singleDate) {
            setPickedDate(null)
            setHoveredDay(null)
            onChange([null, null])
            return null
        }

        onChange([date, null])
        setPickedDate(date)
        return null
    }

    const handleMouseLeave = (/** @type {any} */ event) => {
        typeof onMouseLeave === 'function' && onMouseLeave(event)
        setHoveredDay(null)
    }

    const shouldHighlightDate = (/** @type {string | number | Date | dayjs.Dayjs} */ date, /** @type {{ selected: any; }} */ modifiers) => {
        if (pickedDate instanceof Date && hoveredDay instanceof Date) {
            const result = [hoveredDay, pickedDate]
            result.sort((a, b) => a.getTime() - b.getTime())
            return (
                !modifiers.selected &&
                dayjs(date).subtract(1, 'day').isBefore(result[1]) &&
                dayjs(date).add(1, 'day').isAfter(result[0])
            )
        }

        return false
    }

    const isPickedDateFirstInRange = (/** @type {string | number | Date | dayjs.Dayjs} */ date, /** @type {{ selected: any; }} */ modifiers) => {
        if (pickedDate instanceof Date && hoveredDay instanceof Date) {
            const result = [hoveredDay, pickedDate]
            result.sort((a, b) => a.getTime() - b.getTime())
            return modifiers.selected && dayjs(date).isBefore(result[1])
        }

        return false
    }

    const isPickedDateLastInRange = (/** @type {string | number | Date | dayjs.Dayjs} */ date, /** @type {{ selected: any; }} */ modifiers) => {
        if (pickedDate instanceof Date && hoveredDay instanceof Date) {
            const result = [hoveredDay, pickedDate]
            result.sort((a, b) => a.getTime() - b.getTime())
            return modifiers.selected && dayjs(date).isAfter(result[0])
        }

        return false
    }

    return (
        <CalendarBase
            ref={ref}
            dayStyle={dayStyle}
            onMouseLeave={handleMouseLeave}
            onDayMouseEnter={(/** @type {any} */ date) => setHoveredDay(date)}
            onChange={setRangeDate}
            value={pickedDate}
            range={value}
            dateViewCount={dateViewCount}
            paginateBy={paginateBy || dateViewCount}
            hideOutOfMonthDates={dateViewCount > 1}
            isDateInRange={shouldHighlightDate}
            isDateFirstInRange={isPickedDateFirstInRange}
            isDateLastInRange={isPickedDateLastInRange}
            {...rest}
        />
    )
})
RangeCalendar.displayName = 'RangeCalendar'
RangeCalendar.propTypes = {
    value: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    onChange: PropTypes.func,
    dayStyle: PropTypes.func,
    onMouseLeave: PropTypes.func,
    singleDate: PropTypes.bool,
    dateViewCount: PropTypes.number,
    paginateBy: PropTypes.number,
        
}


RangeCalendar.defaultProps = {
    singleDate: false,
    dateViewCount: 1,
}

export default RangeCalendar
