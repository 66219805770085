import React, {useRef, useState} from 'react'
import classNames from 'classnames'
import useControllableState from '../hooks/useControllableState'
import {useConfig} from '../ConfigProvider'
import DateTable from './tables/DateTable'
import MonthTable from './tables/MonthTable'
import YearTable from './tables/YearTable'
import PropTypes from 'prop-types'

const CalendarBase = React.forwardRef((/** @type any **/ props, /** @type any **/ _ref) => {
    const {
        className,
        dateViewCount = 1,
        dayClassName,
        dayStyle,
        defaultMonth,
        defaultView = 'date',
        disableDate,
        disableOutOfMonth,
        enableHeaderLabel = true,
        firstDayOfWeek = 'monday',
        hideOutOfMonthDates,
        hideWeekdays,
        isDateFirstInRange,
        isDateInRange,
        isDateLastInRange,
        labelFormat = 'MMMM YYYY',
        locale,
        maxDate,
        minDate,
        month,
        monthLabelFormat = 'MMM',
        onChange,
        onDayMouseEnter,
        onMonthChange,
        paginateBy = dateViewCount,
        preventFocus,
        range,
        renderDay,
        style,
        value,
        weekdayLabelFormat = 'dd',
        weekendDays,
        yearLabelFormat = 'YYYY',
        ...rest
    } = props

    const {locale: themeLocale} = useConfig()

    const [selectionState, setSelectionState] = useState(defaultView)

    const finalLocale = locale || themeLocale

    const daysRefs = useRef(
        Array(dateViewCount)
            .fill(0)
            .map(() => [])
    )

    const [_month, setMonth] = useControllableState({
        prop: month,
        defaultProp: defaultMonth !== undefined ? defaultMonth : new Date(),
        onChange: onMonthChange,
    })

    const [yearSelection, setYearSelection] = useState(_month.getFullYear())
    const [monthSelection, setMonthSelection] = useState(_month.getMonth())

    const minYear = minDate instanceof Date ? minDate.getFullYear() : 100
    const maxYear = maxDate instanceof Date ? maxDate.getFullYear() : 10000

    const daysPerRow = 6

    const focusOnNextFocusableDay = (/** @type string **/ direction, /** @type number **/ monthIndex, /** @type any **/ payload, n = 1) => {
        const changeRow = ['down', 'up'].includes(direction)

        const rowIndex = changeRow
            ? payload.rowIndex + (direction === 'down' ? n : -n)
            : payload.rowIndex

        const cellIndex = changeRow
            ? payload.cellIndex
            : payload.cellIndex + (direction === 'right' ? n : -n)

        const dayToFocus = daysRefs.current[monthIndex][rowIndex][cellIndex]

        if (!dayToFocus) {
            return
        }

        if (dayToFocus.disabled) {
            focusOnNextFocusableDay(direction, monthIndex, payload, n + 1)
        } else {
            dayToFocus.focus()
        }
    }

    const handleDayKeyDown = (/** @type {number} */ monthIndex, /** @type {{ rowIndex: number; cellIndex: number; }} */ payload, /** @type {{ key: any; preventDefault: () => void; }} */ event) => {
        switch (event.key) {
            case 'ArrowDown': {
                event.preventDefault()

                const hasRowBelow =
                    payload.rowIndex + 1 < daysRefs.current[monthIndex].length
                if (hasRowBelow) {
                    focusOnNextFocusableDay('down', monthIndex, payload)
                }
                break
            }
            case 'ArrowUp': {
                event.preventDefault()

                const hasRowAbove = payload.rowIndex > 0
                if (hasRowAbove) {
                    focusOnNextFocusableDay('up', monthIndex, payload)
                }
                break
            }
            case 'ArrowRight': {
                event.preventDefault()

                const isNotLastCell = payload.cellIndex !== daysPerRow
                if (isNotLastCell) {
                    focusOnNextFocusableDay('right', monthIndex, payload)
                } else if (monthIndex + 1 < dateViewCount) {
                    if (daysRefs.current[monthIndex + 1][payload.rowIndex]) {
                        daysRefs.current[monthIndex + 1][payload.rowIndex ][0]?.focus()
                    }
                }
                break
            }
            case 'ArrowLeft': {
                event.preventDefault()

                if (payload.cellIndex !== 0) {
                    focusOnNextFocusableDay('left', monthIndex, payload)
                } else if (monthIndex > 0) {
                    if (daysRefs.current[monthIndex - 1][payload.rowIndex]) {
                        daysRefs.current[monthIndex - 1][payload.rowIndex][daysPerRow].focus()
                    }
                }
                break
            }
            default:
                break
        }
    }

    return (
        <div className={classNames('picker-view', className)} {...rest}>
            {selectionState === 'year' && (
                <YearTable
                    value={yearSelection}
                    minYear={minYear}
                    maxYear={maxYear}
                    onChange={(year) => {
                        setMonth(new Date(year, monthSelection, 1))
                        setYearSelection(year)
                        setSelectionState('date')
                    }}
                    className={className}
                    preventFocus={preventFocus}
                    yearLabelFormat={yearLabelFormat}
                />
            )}
            {selectionState === 'month' && (
                <MonthTable
                    value={{
                        month: _month.getMonth(),
                        year: _month.getFullYear(),
                    }}
                    year={yearSelection}
                    onYearChange={setYearSelection}
                    onNextLevel={() => setSelectionState('year')}
                    locale={finalLocale}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(monthValue) => {
                        setMonth(new Date(yearSelection, monthValue, 1))
                        setMonthSelection(monthValue)
                        setSelectionState('date')
                    }}
                    className={className}
                    style={style}
                    preventFocus={preventFocus}
                    yearLabelFormat={yearLabelFormat}
                    monthLabelFormat={monthLabelFormat}
                />
            )}
            {selectionState === 'date' && (
                <DateTable
                    dateViewCount={dateViewCount}
                    paginateBy={paginateBy}
                    month={_month}
                    locale={finalLocale}
                    minDate={minDate}
                    maxDate={maxDate}
                    enableHeaderLabel={enableHeaderLabel}
                    daysRefs={daysRefs}
                    onMonthChange={setMonth}
                    onNextLevel={(view) => setSelectionState(view)}
                    onDayKeyDown={handleDayKeyDown}
                    style={style}
                    dayClassName={dayClassName}
                    dayStyle={dayStyle}
                    disableOutOfMonth={disableOutOfMonth}
                    disableDate={disableDate}
                    hideWeekdays={hideWeekdays}
                    preventFocus={preventFocus}
                    firstDayOfWeek={firstDayOfWeek}
                    value={value}
                    range={range}
                    onChange={onChange}
                    labelFormat={labelFormat}
                    weekdayLabelFormat={weekdayLabelFormat}
                    onDayMouseEnter={onDayMouseEnter}
                    renderDay={renderDay}
                    hideOutOfMonthDates={hideOutOfMonthDates}
                    isDateInRange={isDateInRange}
                    isDateFirstInRange={isDateFirstInRange}
                    isDateLastInRange={isDateLastInRange}
                    weekendDays={weekendDays}
                />
            )}
        </div>
    )
})
CalendarBase.displayName = 'CalendarBase'
CalendarBase.displayName = 'CalendarBase'
CalendarBase.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    dateViewCount: PropTypes.number,
    dayClassName: PropTypes.func,
    dayStyle: PropTypes.func,
    defaultMonth: PropTypes.instanceOf(Date),
    defaultView: PropTypes.oneOf(['date', 'month', 'year']),
    disableDate: PropTypes.func,
    disableOutOfMonth: PropTypes.bool,
    enableHeaderLabel: PropTypes.bool,
    firstDayOfWeek: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
    hideWeekdays: PropTypes.bool,
    hideOutOfMonthDates: PropTypes.bool,
    isDateFirstInRange: PropTypes.func,
    isDateInRange: PropTypes.func,
    isDateLastInRange: PropTypes.func,
    labelFormat: PropTypes.string,
    locale: PropTypes.object,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    monthLabelFormat: PropTypes.string,
    month: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    onDayMouseEnter: PropTypes.func,
    paginateBy: PropTypes.oneOf([1, 2, 3, 4, 6, 12]),
    onMonthChange: PropTypes.func,
    preventFocus: PropTypes.bool,
    range: PropTypes.bool,
    renderDay: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    ]),
    weekdayLabelFormat: PropTypes.string,
    yearLabelFormat: PropTypes.string,
    weekendDays: PropTypes.arrayOf(PropTypes.number),
}


export default CalendarBase
