import {createContext} from 'react'

// @ts-ignore
const DropdownContext = createContext()

export const DropdownContextProvider = DropdownContext.Provider

export const DropdownContextConsumer = DropdownContext.Consumer

export default DropdownContext
