import React from 'react'
import classNames from 'classnames'
import {useConfig} from '../../../ConfigProvider'
import PropTypes from 'prop-types'

function getDayTabIndex({focusable, hasValue, selected, firstInMonth}) {
    if (!focusable) {
        return -1
    }

    if (hasValue) {
        return selected ? 0 : -1
    }

    return firstInMonth ? 0 : -1
}

const Day = React.forwardRef((/** @type any **/ props, /** @type any **/ ref) => {
    const {
        className,
        value,
        selected,
        weekend,
        outOfMonth,
        onMouseEnter,
        styles,
        hasValue,
        firstInRange,
        lastInRange,
        inRange,
        isToday,
        fullWidth,
        firstInMonth,
        focusable,
        hideOutOfMonthDates,
        renderDay,
        disabled,
        ...others
    } = props

    const {themeColor, primaryColorLevel} = useConfig()

    return (
        <button
            {...others}
            type="button"
            ref={ref}
            disabled={disabled}
            onMouseEnter={(event) => onMouseEnter(value, event)}
            tabIndex={getDayTabIndex({
                focusable,
                hasValue,
                selected,
                firstInMonth,
            })}
            className={classNames(
                'date-picker-cell-content',
                disabled && 'date-picker-cell-disabled',
                isToday && `border border-${themeColor}-${primaryColorLevel}`,
                weekend && !disabled && 'date-picker-cell-weekend',
                outOfMonth && !disabled && 'date-picker-other-month',
                outOfMonth && hideOutOfMonthDates && 'd-none',
                !outOfMonth &&
                !disabled &&
                !selected &&
                'date-picker-cell-current-month',
                !disabled &&
                !selected &&
                !inRange &&
                'date-picker-cell-hoverable',
                selected &&
                !disabled &&
                `bg-${themeColor}-${primaryColorLevel} text-gray-100`,
                inRange &&
                !disabled &&
                !firstInRange &&
                !lastInRange &&
                !selected &&
                `bg-${themeColor}-${primaryColorLevel} bg-opacity-10`,
                !inRange && !firstInRange && !lastInRange && 'rounded-lg',
                firstInRange &&
                !disabled &&
                'ltr:rounded-tl-lg ltr:rounded-bl-lg rtl:rounded-tr-lg rtl:rounded-br-lg',
                lastInRange &&
                !disabled &&
                'ltr:rounded-tr-lg ltr:rounded-br-lg rtl:rounded-tl-lg rtl:rounded-bl-lg',
                className
            )}
        >
            {typeof renderDay === 'function'
                ? renderDay(value)
                : value?.getDate()}
        </button>
    )
})
Day.displayName = 'Day'
Day.propTypes = {
    className: PropTypes.string,
    value: PropTypes.instanceOf(Date),
    selected: PropTypes.bool,
    weekend: PropTypes.bool,
    outOfMonth: PropTypes.bool,
    onMouseEnter: PropTypes.func,
    styles: PropTypes.object,
    hasValue: PropTypes.bool,
    firstInRange: PropTypes.bool,
    lastInRange: PropTypes.bool,
    inRange: PropTypes.bool,
    isToday: PropTypes.bool,
    fullWidth: PropTypes.bool,
    firstInMonth: PropTypes.bool,
    focusable: PropTypes.bool,
    hideOutOfMonthDates: PropTypes.bool,
    renderDay: PropTypes.func,
    disabled: PropTypes.bool,
}

export default Day
