import React from 'react'
import {HiX} from 'react-icons/hi'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const CloseButton = React.forwardRef((/** @type any **/ props, /** @type any **/ ref) => {
    const {absolute, className, defaultStyle, svgClass, ...rest} = props
    const closeButtonAbsoluteClass = 'absolute z-10'

    const closeButtonClass = classNames(
        'close-btn',
        defaultStyle && 'close-btn-default',
        absolute && closeButtonAbsoluteClass,
        className
    )

    return (
        <span className={closeButtonClass} role="button" {...rest} ref={ref}>
            <HiX/>
        </span>
    )
})
CloseButton.displayName = 'CloseButton'
CloseButton.propTypes = {
    absolute: PropTypes.bool,
    className: PropTypes.string,
    defaultStyle: PropTypes.bool,
    svgClass: PropTypes.string,
}

CloseButton.defaultProps = {
    defaultStyle: true,
}

export default CloseButton
