import {isSameMonth} from './isSameMonth'

/**
 * @param {Date} date
 * @param {Date} comparison
 */
export function isSameDate(date, comparison) {
    return (
        isSameMonth(date, comparison) && date.getDate() === comparison.getDate()
    )
}
