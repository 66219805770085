import React from 'react'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {PersistGate} from 'redux-persist/integration/react'
import store, {persistor} from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import mockServer from './mock'
import appConfig from 'configs/app.config'
import './locales'
import {ClerkProvider} from "@clerk/clerk-react";
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'

const environment = process.env.NODE_ENV
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
if (environment !== 'production' && appConfig.enableMock) {
    mockServer({environment})
}

function App() {
    const queryClient = new QueryClient()
    return (
        <BrowserRouter history={history}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ClerkProvider publishableKey={clerkPubKey}>
                        <QueryClientProvider client={queryClient}>
                            <Theme>
                                <Layout/>
                            </Theme>
                        </QueryClientProvider>
                    </ClerkProvider>
                </PersistGate>
            </Provider>
        </BrowserRouter>
    )
}

export default App
