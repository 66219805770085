import React from 'react'
import {VscFilePdf, VscFileZip, VscFile} from 'react-icons/vsc'
import PropTypes from 'prop-types'

const BYTE = 1000
const getKB = (/** @type number **/ bytes) => Math.round(bytes / BYTE)

const FileIcon = ({children}) => {
    return <span className="text-4xl">{children}</span>
}
FileIcon.propTypes = {
    children: PropTypes.node.isRequired,
}

const FileItem = (/** @type any **/ props) => {
    const {file, children} = props
    const {type, name, size} = file

    const renderThumbnail = () => {
        const isImageFile = type.split('/')[0] === 'image'

        if (isImageFile) {
            return (
                <img
                    className="upload-file-image"
                    src={URL.createObjectURL(file)}
                    alt={`file preview ${name}`}
                />
            )
        }

        if (type === 'application/zip') {
            return (
                <FileIcon>
                    <VscFileZip/>
                </FileIcon>
            )
        }

        if (type === 'application/pdf') {
            return (
                <FileIcon>
                    <VscFilePdf/>
                </FileIcon>
            )
        }

        return (
            <FileIcon>
                <VscFile/>
            </FileIcon>
        )
    }

    return (
        <div className="upload-file">
            <div className="flex">
                <div className="upload-file-thumbnail">{renderThumbnail()}</div>
                <div className="upload-file-info">
                    <h6 className="upload-file-name">{name}</h6>
                    <span className="upload-file-size">{getKB(size)} kb</span>
                </div>
            </div>
            {children}
        </div>
    )
}
FileItem.propTypes = {
    file: PropTypes.shape({
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        size: PropTypes.number.isRequired,
    }).isRequired,
    children: PropTypes.node.isRequired,
}

export default FileItem
