import React from 'react'
import classNames from 'classnames'
import {useTabs} from './context'
import PropTypes from 'prop-types'

const TabList = React.forwardRef((/** @type any **/ props, /** @type any **/ ref) => {
    const {className, children, ...rest} = props

    const {variant} = useTabs()

    const tabListClass = classNames(
        'tab-list',
        `tab-list-${variant}`,
        className
    )

    return (
        <div role="tablist" className={tabListClass} ref={ref} {...rest}>
            {children}
        </div>
    )
})
TabList.displayName = 'TabList'
TabList.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
}

export default TabList
