import React from 'react'
import PropTypes from 'prop-types'

const TextEllipsis = (/** @type any **/ props) => {
    const {text, maxTextCount} = props

    return (
        <>
            {(text && text.length) > maxTextCount
                ? text.substring(0, maxTextCount - 3) + '...'
                : text}
        </>
    )
}

TextEllipsis.propTypes = {
    maxTextCount: PropTypes.number,
    text: PropTypes.string,
}

TextEllipsis.defaultProps = {
    text: '',
    maxTextCount: 0,
}

export default TextEllipsis
