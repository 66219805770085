import React, {Children} from 'react'
import classNames from 'classnames'
import mapCloneElement from '../utils/mapCloneElement'
import PropTypes from 'prop-types'

const Timeline = React.forwardRef((/** @type any **/ props, /** @type any **/ ref) => {
    const {children, className} = props

    const count = Children.count(children)

    const items = mapCloneElement(children, (/** @type any **/ item, /** @type number **/ index) => ({
        isLast: index === count - 1,
        ...item.props,
    }))

    return (
        <ul ref={ref} className={classNames('timeline', className)}>
            {items}
        </ul>
    )
})
Timeline.displayName = 'Timeline'
Timeline.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
}

export default Timeline
