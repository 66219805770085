import React, {useCallback, useContext} from 'react'
import Menu from './Menu'
import MenuContext from './context/menuContext'
import DropdownItem from './DropdownItem'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const DropdownMenu = React.forwardRef((/** @type any **/ props, /** @type any **/ ref) => {
    const {onToggle, eventKey, title, className, placement, ...rest} = props

    const parentMenu = useContext(MenuContext)

    const handleToggleSubmenu = useCallback(
        (/** @type any **/ _, /** @type any **/ e) => {
            onToggle?.(eventKey, e)
        },
        [eventKey, onToggle]
    )

    const dropdownMenuDefaultClass = `dropdown-menu`
    const dropdownMenuPositionClass = placement

    const dropdownMenuClass = classNames(
        dropdownMenuDefaultClass,
        dropdownMenuPositionClass,
        className
    )

    const dropdownSubmenuClass = classNames(
        dropdownMenuDefaultClass,
        'dropdown-submenu'
    )

    const dropdownSubmenu = (
        <Menu
            className={dropdownSubmenuClass}
            ref={ref}
            onToggle={handleToggleSubmenu}
            placement={placement}
            {...rest}
        />
    )

    if (parentMenu) {
        const {icon, trigger} = props
        const itemClassName = classNames(className)

        return (
            <DropdownItem
                icon={icon}
                trigger={trigger}
                className={itemClassName}
                submenu={dropdownSubmenu}
                eventKey={eventKey}
            >
                {title}
            </DropdownItem>
        )
    }

    return (
        <Menu
            className={dropdownMenuClass}
            placement={placement}
            ref={ref}
            {...rest}
        />
    )
})
DropdownMenu.displayName = 'DropdownMenu'

DropdownMenu.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    eventKey: PropTypes.string,
    className: PropTypes.string,
    placement: PropTypes.oneOf(['left', 'right']),
    onToggle: PropTypes.func,
    icon: PropTypes.node,
    trigger: PropTypes.oneOf(['click', 'hover']),
}

export default DropdownMenu
