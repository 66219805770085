import React from 'react'
import PropTypes from 'prop-types'
import {Scrollbars} from 'react-custom-scrollbars-2'

const ScrollBar = React.forwardRef((/** @type any **/ props, /** @type any **/ ref) => {
    const {direction = 'ltr', ...rest} = props

    return (
        <Scrollbars
            ref={ref}
            renderView={(props) => (
                <div
                    {...props}
                    style={{
                        ...props.style,
                        ...(direction === 'rtl' && {
                            marginLeft: props.style.marginRight,
                            marginRight: 0,
                        }),
                    }}
                />
            )}
            {...rest}
        />
    )
})
ScrollBar.displayName = 'ScrollBar'
ScrollBar.propTypes= {
    direction: PropTypes.oneOf(['ltr', 'rtl']),
    style: PropTypes.object,
}

export default ScrollBar
