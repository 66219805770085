import React from 'react'
import PropTypes from 'prop-types'

const Total = (/** @type any **/ props) => {
    const {total} = props
    return (
        <div className="pagination-total">
            Total <span>{total}</span> Items
        </div>
    )
}
Total.propTypes = {
    total: PropTypes.number.isRequired,
}

export default Total
