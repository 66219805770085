import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Td = React.forwardRef((/** @type any **/ props, /** @type any **/ ref) => {
    const {children, className, asElement: Component, ...rest} = props

    const tdClass = classNames(Component !== 'td' && 'td', className)

    return (
        <Component className={tdClass} ref={ref} {...rest}>
            {children}
        </Component>
    )
})
Td.displayName = 'Td'

Td.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    asElement: PropTypes.string,
}

Td.defaultProps = {
    asElement: 'td',
}

export default Td
