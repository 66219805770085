import React from 'react'
import CalendarBase from './CalendarBase'
import {isSameDate} from './utils'
import PropTypes from 'prop-types'

const Calendar = (/** @type any **/ props) => {
    const {multipleSelection, value, onChange, ...rest} = props

    const handleChange = (/** @type Date **/ date) => {
        if (!multipleSelection) {
            return onChange(date)
        }

        const isSelected = value.some((/** @type Date **/ val) => isSameDate(val, date))

        return onChange(
            isSelected
                ? value.filter((/** @type any  **/ val) => !isSameDate(val, date))
                : [...value, date]
        )
    }

    return <CalendarBase onChange={handleChange} value={value} {...rest} />
}
Calendar.propTypes = {
    multipleSelection: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.instanceOf(Date)),
        PropTypes.instanceOf(Date)]),
    onChange: PropTypes.func,
}

export default Calendar
