import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const THead = React.forwardRef((/** @type any **/ props, /** @type any **/ ref) => {
    const {children, asElement: Component, className, ...rest} = props

    const tHeadClass = classNames(Component !== 'thead' && 'thead', className)

    return (
        <Component className={tHeadClass} {...rest} ref={ref}>
            {children}
        </Component>
    )
})
THead.displayName = 'THead'

THead.propTypes = {
    asElement: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
}

THead.defaultProps = {
    asElement: 'thead',
}

export default THead
