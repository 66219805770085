import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import PropTypes from 'prop-types'

const RichTextEditor = React.forwardRef((/** @type any **/ props, /** @type any **/ ref) => {
    return (
        <div className="rich-text-editor">
            <ReactQuill ref={ref} {...props} />
        </div>
    )
})
RichTextEditor.displayName = 'RichTextEditor'
RichTextEditor.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
}

export default RichTextEditor
